


































import type { PropType } from '@nuxtjs/composition-api';
import {
computed, defineComponent,
toRef,
useContext,
} from '@nuxtjs/composition-api';
import type { TransformedCustomerAddress } from '~/modules/customer/composables/types';
import userAddressesGetters from '~/modules/customer/getters/userAddressesGetters';

export default defineComponent({
  name: 'UserAddressDetails',
  props: {
    address: {
      type: Object as PropType<TransformedCustomerAddress>,
      required: true,
    },
  },
  setup(props) {
    const address = toRef(props, 'address');
    const { app: { i18n } } = useContext();

    const userAddress = computed(() => ({
      firstName: userAddressesGetters.getFirstName(address.value),
      lastName: userAddressesGetters.getLastName(address.value),
      street: userAddressesGetters.getStreetName(address.value),
      streetNumber: userAddressesGetters.getApartmentNumber(address.value),
      postalCode: userAddressesGetters.getPostCode(address.value),
      city: userAddressesGetters.getCity(address.value),
      province: userAddressesGetters.getProvince(address.value) || '',
      country: userAddressesGetters.getCountry(address.value),
      phone: userAddressesGetters.getPhone(address.value),
      isDefault: userAddressesGetters.isDefault(address.value),
      isDefaultShipping: userAddressesGetters.isDefaultShipping(address.value),
      isDefaultBilling: userAddressesGetters.isDefaultBilling(address.value),
      company: userAddressesGetters.getCompanyName(address.value),
    }));

    const getSpecialAddressTagText = computed(() => {
      const tagsTextArray = [];
      if (userAddress.value.isDefaultShipping) {
        tagsTextArray.push(i18n.t('Default Shipping Address'));
      }
      if (userAddress.value.isDefaultBilling) {
        tagsTextArray.push(i18n.t('Default Billing Address'));
      }
      if (tagsTextArray.length > 0) {
        return `${tagsTextArray.join(', ')}`;
      }
      return '';
    });

    return {
      userAddress,
      getSpecialAddressTagText,
    };
  },
});
